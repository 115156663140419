@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");

html,
body {
  height: 100%;
}
body {
  font-family: "Roboto", sans-serif;
  color: var(--gray-700) !important;
}

/* custom menu active */
.p-menuitem span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background-color: #4b545c;
}
.p-menu .p-menuitem-link:hover {
  background-color: #4b545c;
}
.p-menu .p-menuitem-link .p-menuitem-text,
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: rgba(255, 255, 255, 0.8);
  font-size: 80%;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 80%;
}

.p-menu .p-submenu-header {
  background-color: #343a40;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  font-size: 90%;
}

.p-menu {
  background-color: #343a40;
  border: 0;
  border-radius: 0;
  height: 100%;
  width: auto;
}
.p-menuitem:focus-visible {
  outline: none;
}
.p-menu .p-menuitem-link:hover,
.p-menu .p-menuitem-link:focus,
.p-menu .p-menuitem-link:focus-visible {
  outline: none;
  box-shadow: none;
}
hr {
  border-top-color: #ced4da;
  border-bottom-color: transparent;
  opacity: 0.7;
}

.p-float-label input:focus ~ label,
.p-float-label input:-webkit-autofill ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  background-color: #fff;
  top: 0.05rem;
  left: 0.3rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.right-3 {
  right: 3%;
}
.bottom-3 {
  bottom: 3%;
}
.template-select-image {
  width: 100%;
}
.template-select-item {
  width: calc(50% - 48px);
  max-width: 500px;
}

